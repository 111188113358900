$(document).ready(function(){

  $('.nav').click(function(){
    if($('body').hasClass('page-about')){
      $('body').removeClass('page-about');
    }else{
      $('body').addClass('page-about');
    }    
  });

  $('.nav').mouseover(function(){    
    $('body').addClass('navhover');
  }).mouseout(function(){
    $('body').removeClass('navhover');
  });
  $('.projects').mouseover(function(){    
    $('body').addClass('projectshover');
  }).mouseout(function(){
    $('body').removeClass('projectshover');
  });
  $('.logo').mouseover(function(){    
    $('body').addClass('logohover');
  }).mouseout(function(){
    $('body').removeClass('logohover');
  });

  $('.project').click(function(){        

    if($(this).hasClass('open')){
      $(this).removeClass('open');      
      $('.updown').removeClass('open');      
      $('body').removeClass('page-detail')
    }else{
      $('.project').removeClass('open');
      $(this).addClass('open');
      $('body').addClass('page-detail')
      $('.updown').addClass('open');
      loadImgs($(this).attr('data-imgs'));
    }
  });

  $('.updown').click(function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.project').removeClass('open');
       $('body').removeClass('page-detail')
    }
  });

  
  function loadImgs(total){
    
    var imgs = total.split(',');
    var imgs = imgs.reverse();
    $('.gallery').html('');
    for (var i = imgs.length - 1; i >= 0; i--) {
      
      var img = new Image();
      img.src = '/img/' + imgs[i];
      img.onload = function () {
       this.classList.add('loaded');
      };
      var imgNode = document.createElement('div');
      imgNode.className = "img";
      imgNode.append(img);
      $('.gallery').append(imgNode);
    }    
  }
  
});